@import url('https://fonts.googleapis.com/css?family=Roboto');
/*Principal*/
.App {
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto';
/*  height: 100vh;*/
}
/*Elementos gerais*/
a {
  color: #969696;
  text-decoration: none;
}
a:visited {
  color: #969696;
  text-decoration: none;
}
button {
  background-color: white!important;
  color: #41a1d6!important;
  padding: 5px 20px!important;
  margin: 0 5px!important;
}
figure > img {
  width: 100%;
  height: auto;
}
iframe {
  border-width: 0;
}
/*Carregando*/
.loading {
  position: absolute;
  margin-top: 20px;
  left: 50%
}
/*Tela Principal*/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 170px;
}
.menu_bar {
  padding-top: 40px;
  padding-bottom: 40px;
}
.sidebarcontent{
  position: absolute;
  padding: 10px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  background-color: white;
}
.Button {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: white;
}
.buttonIcon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-color: #969696;
  border-style: solid;
  border-width: 2px;
  margin-right: 10px;
}
.active {
  color: #3d3d3d!important;
  font-weight: bold;
}
.active > .Button > .buttonIcon {
  border-color: #3d3d3d;
  background-color: #3d3d3d;
}
.social_bar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;  
  filter: invert(0.24);
}
.menu_button {
  display: none;
}
/*Conteúdo principal*/
.body {  
  position: absolute;
  left: 170px;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  max-width: 1030px;
  padding: 0;
}
.maincontent {
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  padding: 20px;
}
/*Conteúdo das páginas*/
/*Home*/
.home{
  padding-top: 30vh;
  padding-left: 20vh;
  padding-right: 0;
  background-image: url(https://modular.org.br/wp-content/uploads/2021/02/22-scaled.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home_content {
  padding: 10px;
  background-color: #41a1d6;
  color: white;
}
/*Sobre*/
.sobre {  
  background-color: #41a1d6;
  width: 100%;
  height: 100vh;
}
.sobre.maincontent {
  color: white;
  padding: 0 20px;
}
.sobre_wrapper {
  max-width: calc( 100vw - 40px);
  position: absolute;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sobre_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.MuiCardContent-root.css-46bh2p-MuiCardContent-root {
  padding: 10px;
}
.sobre_button {
  background-color: white!important;
}
/*Blog e Atuação*/
p.author {
  margin: 0;
  font-size: 0.8rem!important;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.card {
  position: relative;
}
.post_title_card {
  font-size: 1.2rem!important;  
}
.card_excerpt {
  height: 140px;
  width: calc(100% - 20px);
  position: absolute;
  overflow: hidden;
  padding: 0 10px 0 10px;
  text-align: justify;
  background-image: linear-gradient(rgba(76, 76, 76, 0.713), white);
  transform: scaleY(0);    
  transform-origin: bottom;
  transition: transform 0.26s ease;
}
.card_excerpt p {
  color: white;  
}
.card:hover .card_excerpt {
  height: 120px;
  width: calc(100% - 20px);
  padding: 10px;
  transition: transform 0.26s ease;
  transform-origin: bottom;
  transform: scaleY(1);
}
.cards_item {
  display: flex;
  padding: 10px;
}
.cards_item.user {
  min-width: 300px;
}
.post img {
  max-width: 100%;
}
.tags {
  display: flex;
  flex-direction: row;
}
.tags h4 {
  margin: 0;
}
.tags .selecionado {
  background-color: rgb(211, 211, 211)!important;
}
/*Equipe*/
.associado {
  background-color: #ed5253;
}
.associado_card.equipe {
  min-width: 300px;
  max-width: 33.3%;
}
.associado_card {
  width: 100%;
}
.associado_card > .cards_item.user {
  width: 100%;
}
.associado_card.post * {
  color: black!important;
}
.equipe {
  background-color: #ed5253;
}
.fixed_equipe > h3 {
  margin: 0;
  padding: 10px;
  color: white;
}
.user_avatar {
  width: 120px;
  height: 120px;
  min-width: 120px;
  border-radius: 50%;
  position: relative;
}
.social_equipe {
  margin: 0;
  flex-direction: row;
  list-style: none;
  gap: 10px;
  padding: 0;
  display: flex;
  height: 0;
  width: 120px;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  top: 100%; right: 0; bottom: 0; left: 0;
  background: rgba(0,0,0,0.3);
  transform: scaleX(0);    
  transform-origin: center;
  transition: transform 0.26s ease;
}
.user_avatar:hover > .social_equipe {
  top: 0;
  height: 60px;
  padding-top: 50%;
  transform: scaleX(1);    
  transform-origin: center;
  transition: transform 0.26s ease;
}
img.social-icon-equipe {
  filter: invert(1);
}
.user_data {
  padding: 10px;
  color: white;
}
.user_data span {  
  color: white;
}
.user_data a {
  color: white;
}
.user_bio {
  color: white;
  text-align: justify;
}
.user_profile {  
  color: white;
}
.user_name {
  text-transform: uppercase;
  font-weight: bold;
}
.skeleton_FE {
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5); 
}
/*Transparencia*/
.transparencia {
  padding: 20px;
}
.transparencia_card {
  padding: 10px;
  margin: 10px;
}
/*Responsivo*/
@media (max-width: 600px) {
  .App {
    flex-direction: column;
  }
  .sidebar {
    width: 100%;
    height: 120px;
  }
  .sidebarcontent {
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    width: 100%;
  }
  .body {
    left: 0;
    min-width: 100%;
    height: calc(100% - 120px);
    position: fixed;
    top: 120px;
    z-index: -1;
    overflow-x: scroll;
  }
  .menu_button {
    display: block;
  }
  .menu_bar.closed {
    left: 0;
    position: absolute;
    top: 120px;
    width: 100%;
    z-index: 1000;
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.26s ease;
    background-color: white;
  }
  .menu_bar.open {
    left: 0;
    position: absolute;
    display: block;
    top: 120px;
    width: 100%;
    z-index: 1000;
    background-color: white;
    transition: transform 0.26s ease;
    transform-origin: top;
    transform: scaleY(1);
  }
  .mobile_social {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: -webkit-right
  }
  .meun_button {
    text-align: right!important;
  }
  .Button {
    background-color: white;
  }
  .associado_card.equipe {
    min-width: 300px;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .cards_item {
    width: calc( 100% - 20px);
  }
  .card {
    max-width: 100%!important;
    width: 100%
  }
  .home {
    width: auto;
    padding-left: 20%;
    padding-top: 30vh;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  .cards_item {
    width: calc(50% - 20px);
  }
  .card {
    max-width: 100%!important;
    width: 100%
  }
  .home {
    width: auto;
    padding-left: 20%;
    padding-top: 30vh;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  .cards_item {
    width: calc(100% - 20px);
  }
  .card {
    max-width: 100%!important;
    width: 100%
  }
  .home {
    width: auto;
    padding-left: 20%;
    padding-top: 30vh;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .cards_item {
    width: calc(50% - 20px);
  }
  .card {
    max-width: 100%!important;
    width: 100%
  }
  .home {
    width: auto;
    padding-left: 20%;
    padding-top: 30vh;
  }
}

@media (min-width: 901px) {
  .cards_item {
    width: calc(33.33% - 20px);
  }
  .card {
    max-width: 100%!important;
    width: 100%
  }
}
@media (min-width: 601px) {
  .maincontent {
    bottom: 0;
  }
}


